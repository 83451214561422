import * as React from "react";
import { Link, useHistory } from "react-router-dom";
import { AuthContext } from "@zboxglobal/zboxauth";
import { CartContext, ICartContext } from "../../contexts/CartContext";
import GlobalContext from "../../contexts/GlobalContext";
import GenerateLink from "../../helpers/GenerateLink";
import useTrackers from "../../hooks/useTrackers";
import menu from "../../images/ui/menu.svg";
import FleetSideBar from "../fleet_sidebar/FleetSideBar";
import AccountTab from "./AccountTab";
import checkoutStyles from "./CheckoutNavbar.module.scss";
import styles from "./Navbar.module.scss";
import NavSidebar from "./NavSideBar";
import ScrollNavBar from "./ScrollNavBar";

interface IPlainNav extends IGoBack {
  Title: string;
}
interface ICheckOutNav extends IGoBack {
  cartContext: ICartContext;
}

interface IGoBack {
  goBack: () => void;
}

const Navbar = (props: { children?: React.ReactNode }) => {
  const globalContext = React.useContext(GlobalContext);
  const cartContext = React.useContext(CartContext);
  const history = useHistory();
  const goBack = () => history.goBack();

  return {
    CHECKOUT: <CheckOutNavBar goBack={goBack} cartContext={cartContext}></CheckOutNavBar>,
    STANDARD: <StandardNavBar Search={props.children}></StandardNavBar>,
    NoSearchMobile: <StandardNavBar Search={props.children} hideSearchMobile={true}></StandardNavBar>,
    EditMachinePage: <PlainNavBar goBack={goBack} Title="Edit Machine"></PlainNavBar>,
    ScrollNavBar: <ScrollNavBar></ScrollNavBar>,
    HIDDEN: null,
  }[globalContext.header];
};

const PlainNavBar = (props: IPlainNav) => {
  return (
    <div className={styles["plainNavBar"] + " d-flex"}>
      <div onClick={props.goBack}>
        <span>Back</span>
      </div>
      <h2>{props.Title}</h2>
      <div></div>
    </div>
  );
};

const StandardNavBar = (props: { Search: React.ReactNode; hideSearchMobile?: boolean }) => {
  const globalContext = React.useContext(GlobalContext);
  const context = React.useContext(AuthContext);

  const globalClickHandler = () => {
    globalContext.closeOverlay();
  };

  const redirectToAdmin = () => {
    context.client.SaveCookieRedirect("/Admin").then(
      () => {},
      () => {
        alert("Failed to save cookie");
      }
    );
  };

  const tracker = useTrackers();
  return (
    <>
      <div className={`${props.hideSearchMobile ? styles.hideSearchMobile : ""} ${styles.container}`}>
        <div
          className={
            globalContext.isAccountOpen ? `${styles.top} ${styles["dropdown-z-index-top"]}` : `${styles.top} ${styles["top-visible"]}`
          }
        >
          <div className={styles["top-background"]}>
            <div className={styles.search}>
              <Link to="/" className={styles["logo-container"]}>
                <div className={styles.logo} />
              </Link>
              <div className={styles["search-bar-container"]}>{props.Search}</div>
              <div className={styles.reserved}>
                <div>
                  <a
                    href={`tel://${process.env.REACT_APP_PHONE_NUMBER}`}
                    onClick={() => {
                      tracker.trackEvent(`${process.env.TRACK_OPEN_PHONE_NAME}`, `${process.env.TRACK_OPEN_PHONE_VALUE}`);
                    }}
                  >
                    {process.env.REACT_APP_PHONE_NUMBER}
                  </a>
                </div>
                <div className="b1b">{process.env.REACT_APP_PhoneOpenSimple}</div>
              </div>
            </div>
          </div>
        </div>
        <div className={globalContext.isAccountOpen ? `${styles.bottom} ${styles["dropdown-z-index-bottom"]}` : `${styles.bottom}`}>
          <div className={styles["bottom-background"]}>
            <div className={styles["departments-container"]}>
              <div className={styles.departments}>
                <button onClick={globalContext.toggleDepartments} className={styles.menu}>
                  <img alt="Menu" src={menu} />
                </button>
                <Link to="/" className={styles["logo-container-mobile"]}>
                  <div className={styles.logo}></div>
                </Link>
                <NavSidebar></NavSidebar>
                <FleetSideBar></FleetSideBar>
              </div>
            </div>
            <div className={styles["bottom-right"]}>
              <div className={styles["site-links"]}>
                <Link onClick={globalClickHandler} to={GenerateLink.ForHelp()}>
                  Help
                </Link>
                <Link onClick={globalClickHandler} to={GenerateLink.ForTeam()}>
                  Meet The Team
                </Link>
                <Link onClick={globalClickHandler} to={GenerateLink.ForSellWithUs()}>
                  Sell With Us
                </Link>
                {context.status.isAdmin ? (
                  <button className={styles.admin} onClick={redirectToAdmin}>
                    Admin
                  </button>
                ) : null}
              </div>
              <AccountTab color="white"></AccountTab>
            </div>
          </div>
        </div>
      </div>
      {/* <div className={`${styles["sales-banner"]} `}>
        <div className={styles["info-text"]}>
          ⚠️ 🛒 Our online checkout is currently down for maintenance. We're still taking orders over the phone! ☎️ Please call us at{" "}
          <a href={`tel:${process.env.REACT_APP_PHONE_NUMBER}`}>{process.env.REACT_APP_PHONE_NUMBER}</a> ⚠️
        </div>
      </div> */}
    </>
  );
};

const CheckOutNavBar = (props: ICheckOutNav) => {
  return (
    <div className={checkoutStyles.container}>
      <div className={checkoutStyles["left-container"]}>
        <button className={checkoutStyles["mobile-back-button"]} onClick={props.goBack}>
          Back
        </button>
        <Link to="/">
          <div className={checkoutStyles.logo} />
        </Link>
      </div>

      <div className={checkoutStyles["center-container"]}>
        Checkout
        <span className={checkoutStyles["desktop-items-display"]}> &nbsp;- {props.cartContext.cart?.itemCount} Items</span>
      </div>

      <div className={checkoutStyles["right-container"]}>
        <div className={checkoutStyles["mobile-placeholder"]} />
        <div className={checkoutStyles["contact-container"]}>
          <div className="row">
            <div className="mr-5">Need Help?</div>
            <div>{process.env.REACT_APP_PhoneOpenSimple}</div>
          </div>
          <div>{process.env.REACT_APP_PHONE_NUMBER}</div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
